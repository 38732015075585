// Here you can add other styles
.c-main {
  background-color: #edf1f5;
}

.c-sidebar {
  background-color: #01a768 !important;
}

.c-sidebar-nav-link:hover {
  background-color: #027348 !important;
}

.c-sidebar-minimizer {
  color: antiquewhite;
}

.c-sidebar-nav-dropdown-items {
  background-color: #028d58;
}

.c-header {
  background-color: #edf1f5;
  border-style: none;
}

.c-subheader {
  background-color: #edf1f5;
  border-top-style: none;
  border-style: none;
}

.c-footer {
  background-color: #edf1f5;
  border-style: none;
}

.tableCss {
  height: 1000px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4caf50;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkbox-container {
  padding: 15px 10px;
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.left-tile {
}

.right-tile {
  color: $black;

  .small-card {
    display: flex;
    flex-direction: row;
    align-items: center;

    .small-img {
      width: 40%;
      text-align: center;

      img {
        width: 60px;
        height: auto;
        // margin-right: 10px;
      }
    }

    .small-card-detail {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      line-height: 25px;

      .number {
        font-size: 25px;
        font-weight: 700;
      }
    }
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .tab {
    width: 30%;
    margin: 10px 0;

    .information-detail {
      display: flex;
      flex-direction: row;
      align-items: center;

      .text {
        width: 50%;
        font-weight: 600;
        line-height: 2rem;
      }
    }
  }
}

.card {
  border-radius: 1rem;
  border-style: none;
}

.card-header {
  border-radius: 1rem;
  border-style: none;
  //background-color: #01a768;
  //color: #fff;
  &:first-child {
    border-radius: 1rem 1rem 0 0;
    border-style: none;
  }
  padding-bottom: 0;
}

.multi-tabs {
  .card {
    border-radius: 0 0 1rem 1rem;
  }

  .nav {
    background-color: #fff;
    border-style: none;
    border-radius: 1rem 1rem 0 0;
    margin-bottom: 2px;
    justify-content: space-around;
  }

  .nav-item {
    background-color: #fff;
    border-radius: 1rem 1rem 0 0;

    .active {
      border-radius: 1rem 1rem 0 0;
      color: black;
      background-color: #fff;

      &::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 100%;
        padding-top: 5px;
        border-bottom: 2px solid #01a768;
      }
    }
  }

  .nav-link {
    color: gray;
    border-style: none;

    &:hover {
      border-style: none;
    }
  }
}

.comingsoon {
  text-align: center;
  font-size: 3rem;
}

.c-body {
  .c-main {
    padding-top: 0;
  }
}